var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Sportangebote")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"large":"","rounded":"","color":"primary"},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Sportangebot erstellen ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"rounded":"","text":_vm.view != 'Eingereicht',"color":"secondary"},on:{"click":function($event){_vm.view = 'Eingereicht'}}},[_vm._v("Eingereicht")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"rounded":"","text":_vm.view != 'Veröffentlicht',"color":"secondary"},on:{"click":function($event){_vm.view = 'Veröffentlicht'}}},[_vm._v("Veröffentlicht")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"rounded":"","text":_vm.view != 'Abgelaufen',"color":"secondary"},on:{"click":function($event){_vm.view = 'Deaktiviert'}}},[_vm._v("Deaktiviert")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","filled":"","rounded":"","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-window',{attrs:{"vertical":""}},[(_vm.view == 'Eingereicht')?_c('v-window-item',{staticClass:"elevation-0 rounded-xl pa-2"},[_c('v-data-table',{staticClass:"elevation-5 rounded-xl",attrs:{"headers":_vm.headers,"items":_vm.eingereichte_angebote,"item-key":"name","show-select":"","fixed-header":"","search":_vm.search,"locale":"de-DE","footer-props":{
              itemsPerPageText: 'Zeilen pro Seite:',
            }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Es wurden keine Sportangebote gefunden. ")]},proxy:true},{key:"item.name",fn:function(ref){
            var index = ref.index;
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","rounded":"","to":("/admin/sportangebote/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,false,730272610),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),(_vm.view == 'Veröffentlicht')?_c('v-window-item',{staticClass:"elevation-0 rounded-xl pa-2"},[_c('v-data-table',{staticClass:"elevation-5 rounded-xl",attrs:{"headers":_vm.headers,"items":_vm.veroeffentlichte_angebote,"item-key":"name","show-select":"","fixed-header":"","search":_vm.search,"locale":"de-DE","footer-props":{
              itemsPerPageText: 'Zeilen pro Seite:',
            }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Es wurden keine Sportangebote gefunden. ")]},proxy:true},{key:"item.name",fn:function(ref){
            var index = ref.index;
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","rounded":"","to":("/admin/sportangebote/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,false,730272610),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),(_vm.view == 'Deaktiviert')?_c('v-window-item',{staticClass:"elevation-0 rounded-xl pa-2"},[_c('v-data-table',{staticClass:"elevation-5 rounded-xl",attrs:{"headers":_vm.headers,"items":_vm.deaktivierte_angebote,"item-key":"name","show-select":"","fixed-header":"","search":_vm.search,"locale":"de-DE","footer-props":{
              itemsPerPageText: 'Zeilen pro Seite:',
            }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Es wurden keine Sportangebote gefunden. ")]},proxy:true},{key:"item.name",fn:function(ref){
            var index = ref.index;
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","rounded":"","to":("/admin/sportangebote/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,false,730272610),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }