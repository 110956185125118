<template>
  <v-container class="mt-5">
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>Sportangebote</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn large rounded color="primary" @click="create">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Sportangebot erstellen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <v-btn rounded :text="view != 'Eingereicht'" color="secondary" @click="view = 'Eingereicht'">Eingereicht</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn rounded :text="view != 'Veröffentlicht'" color="secondary" @click="view = 'Veröffentlicht'">Veröffentlicht</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn rounded :text="view != 'Abgelaufen'" color="secondary"  @click="view = 'Deaktiviert'">Deaktiviert</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          filled
          rounded
          label="Suche"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-window vertical>
          <v-window-item class="elevation-0 rounded-xl pa-2" v-if="view == 'Eingereicht'">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="eingereichte_angebote"
              item-key="name"
              show-select
              class="elevation-5 rounded-xl"
              fixed-header
              :search="search"
              locale="de-DE"
              :footer-props="{
                itemsPerPageText: 'Zeilen pro Seite:',
              }"
            >
              <template v-slot:no-data>
                Es wurden keine Sportangebote gefunden.
              </template>
              <template v-slot:item.name="{ index, item }">
                <v-btn text rounded :to="`/admin/sportangebote/${item.id}`">
                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-btn>
              </template>
            </v-data-table>
          </v-window-item>
          <v-window-item class="elevation-0 rounded-xl pa-2" v-if="view == 'Veröffentlicht'">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="veroeffentlichte_angebote"
              item-key="name"
              show-select
              class="elevation-5 rounded-xl"
              fixed-header
              :search="search"
              locale="de-DE"
              :footer-props="{
                itemsPerPageText: 'Zeilen pro Seite:',
              }"
            >
              <template v-slot:no-data>
                Es wurden keine Sportangebote gefunden.
              </template>
              <template v-slot:item.name="{ index, item }">
                <v-btn text rounded :to="`/admin/sportangebote/${item.id}`">
                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-btn>
              </template>
            </v-data-table>
          </v-window-item>
          <v-window-item class="elevation-0 rounded-xl pa-2" v-if="view == 'Deaktiviert'">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="deaktivierte_angebote"
              item-key="name"
              show-select
              class="elevation-5 rounded-xl"
              fixed-header
              :search="search"
              locale="de-DE"
              :footer-props="{
                itemsPerPageText: 'Zeilen pro Seite:',
              }"
            >
              <template v-slot:no-data>
                Es wurden keine Sportangebote gefunden.
              </template>
              <template v-slot:item.name="{ index, item }">
                <v-btn text rounded :to="`/admin/sportangebote/${item.id}`">
                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-btn>
              </template>
            </v-data-table>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import router from '../../../router'

export default {
  name: 'Verein',
  data() {
    return {
      selected: [],
      view: 'Eingereicht',
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Verein',
          value: 'verein.name',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    eingereichte_angebote(){
      return this.sportangebote.all.filter(item => item.active != true && item.approved == false)
    },
    veroeffentlichte_angebote(){
      return this.sportangebote.all.filter(item => item.active == true)
    },
    deaktivierte_angebote(){
      return this.sportangebote.all.filter(item => item.active == false)
    },
  },
  methods: {
    verein(id) {
      return this.vereine.data.find((verein) => verein.id === id)
    },
    async create() {
      const docRef = await addDoc(collection(this.db, 'Sportangebote'), {
        name: '',
        author: 'Admin',
        verein: '',
        beschreibung: '',
        sportart: '',
        veranstaltungsort: {
          name: '',
          adresse: '',
          plz: '',
          ort: '',
        },
        altersgruppen: [],
        geschlecht: [],
        teilnehmerzahl: 15,
        termin: { datum: '', beginn: '', ende: '' },
        termine: [{ datum: '', beginn: '', ende: '' }],
        zeitraum: [
          {
            wochentag: '',
            datum: { von: '', bis: '' },
            uhrzeit: { beginn: '', ende: '' },
          },
        ],
        wiederholung: 'Einmaliger Termin',
        material: [],
        ansprechpartner: [
          {
            vorname: '',
            nachname: '',
            telefon: '',
            telefonpublic: false,
            whatsapp: false,
            email: '',
            emailvalid: false,
            funktion: '',
          },
        ],
        fotos: [],
        sonstiges: '',
        created_at: serverTimestamp(),
        created: false,
      })
      router.push('/admin/sportangebote/' + docRef.id)
    },
  },
}
</script>
